import React, {useEffect, useMemo, useState} from "react";
import useRouter from "../utils/use-router";
import {useGlobalMutation, useGlobalState} from "../utils/container";
import RTCClient from "../utils/rtc-client";
import useStream from "../utils/use-stream";
import StreamPlayer from "./room/StreamPlayer";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from 'clsx'
import AgoraRTC from 'agora-rtc-sdk'
import { RandomRange } from '../utils/Random'
import { encryptKey } from '../utils/AESUtil'
import Container from "@material-ui/core/Container";
import Img from "../utils/image";

const useStyles = makeStyles({
	menu: {
		height: '150px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	customBtn: {
		width: '50px',
		height: '50px',
		marginLeft: '20px',
		borderRadius: '26px',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		backgroundSize: '50px',
		cursor: 'pointer'
	},
	leftAlign: {
		display: 'flex',
		flex: '1',
		justifyContent: 'space-evenly'
	},
	rightAlign: {
		display: 'flex',
		flex: '1',
		justifyContent: 'center'
	},
	menuContainer: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		zIndex: '2'
	}
})

const RoomPage = (props) => {
	const classes = useStyles()
	const routerCtx = useRouter()
	const stateCtx = useGlobalState()
	const mutationCtx = useGlobalMutation()
	
	const localClient = useMemo(() => {
		// console.error('开启日志')
		// AgoraRTC.Logger.enableLogUpload();
		// AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.INFO);
		// console.error('日志上传已开启...')
		const client = new RTCClient()
		if(!client._created){
			client.createClient( {mode: stateCtx.mode, codec: stateCtx.codec})
			client._created = true
		}
		
		client.on('stream-published', (evt) => {
			localClient.startLiveStreaming()
			console.error("startLiveStreaming ing...")
		})
		
		return client
	}, [stateCtx.mode, stateCtx.codec])
	
	const [localStream, currentStream] = useStream(localClient)
	const [muteVideo, setMuteVideo] = useState(stateCtx.muteVideo)
	const [muteAudio, setMuteAudio] = useState(stateCtx.muteAudio)
	
	// const [query, setQuery] = useState('');
	const [nickName, setNickName] = useState('')
	const [photo, setPhoto] = useState('')
	
	const config = useMemo(() => {
		return {
			token: stateCtx.config.token,
			channel: stateCtx.config.channelName,
			microphoneId: stateCtx.config.microphoneId,
			cameraId: stateCtx.config.cameraId,
			resolution: stateCtx.config.resolution,
			muteVideo: muteVideo,
			muteAudio: muteAudio,
			uid: stateCtx.config.uid,
			host: stateCtx.config.host,
			url: stateCtx.config.url
		}
	}, [stateCtx, muteVideo, muteAudio])
	// console.error(stateCtx)
	// console.error(config)
	useEffect(() => {
		return () => {
			localClient && localClient.leave(() => mutationCtx.clearAllStream())
		}
	}, [localClient])
	
	const history = routerCtx.history
	// console.error(routerCtx)
	const params = new URLSearchParams(window.location.search)
	// console.error(props)
	useMemo(() => {
		let query = props.location.search
		if(query.indexOf('agoraRoomId') !== -1
			&& query.indexOf('userId') !== -1
			&& query.indexOf('cataId') !== -1) {
			const arr = query.split('&')
			
			// const agoraRoomId = arr[0].split('=')[1]
			//
			// const userId = arr[1].split('=')[1]
			// const cataId = arr[2].split('=')[1]
			
			for(let j = 0; j < arr.length; j++){
				if(arr[j].indexOf('agoraRoomId') !== -1){
					var agoraRoomId = arr[j].split('=')[1]
					console.error(agoraRoomId)
					continue
				}
				if(arr[j].indexOf('cataId') !== -1){
					var cataId = arr[j].split('=')[1]
					console.error(cataId)
					continue
				}
				if(arr[j].indexOf('userId') !== -1){
					var userId = arr[j].split('=')[1]
					console.error(userId)
					continue
				}
			}
			
			let formData = new FormData()
			let random = RandomRange(16,16)
			formData.append('access_token',encryptKey(random));
			formData.append('iv', random);
			formData.append('id', agoraRoomId);
			formData.append('userId', userId);
			formData.append('userType', '102');
			formData.append('expire', '7200');//失效时间（秒）
			fetch('https://test.ixuesiwei.com/app/course/getCourseAccess',{
				method: 'post',
				body:formData
			})
				.then((res) => {
					return res.json()
				})
				.then((result) => {
					// console.error(result)
					// console.error(result.code)
					
					if(result.code === 200) {
						// console.error(result.result.token.channelName)
						// console.error(result.result.urlPush)
						// console.error('这里是原uid：'+stateCtx.config.uid)
						// console.error('这里是原channelName：'+stateCtx.config.channelName)
						// console.error('这里是原url：'+stateCtx.config.url)
						mutationCtx.updateConfig({
							url: result.result.urlPush,
							uid: result.result.token.userId,
							channelName: result.result.token.channelName,
						})
						// console.error('这里是uid：'+stateCtx.config.uid)
						// console.error('这里是channelName：'+stateCtx.config.channelName)
						// console.error('这里是url：'+stateCtx.config.url)
						let formData = new FormData()
						let random = RandomRange(16,16)
						formData.append('access_token',encryptKey(random));
						formData.append('iv', random);
						formData.append('id',userId)
						fetch('https://test.ixuesiwei.com/app/course/getTeaInfo',{
							method: 'post',
							body:formData
						})
							.then((res) => {
								return res.json()
							})
							.then((result) => {
								console.error(result)
								console.error(result.result.nickName)
								setNickName(result.result.nickName)
								console.error(result.result.photo)
								setPhoto(result.result.photo)
							})
							.catch((err) => {
								console.error(err)
							})
					} else if(result.code === 500){
						throw new Error(result.message)
					}
					
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}, [])
	
	// useEffect(() => {
	//
	// 	if(access_token && iv && agoraRoomId && cataId && userId){
	// 		mutationCtx.updateConfig({ token: access_token, appId: agoraRoomId, uid: userId,
	// 		iv: iv, cataId: cataId})
	// 	}
	// }, [params])
	
	useEffect(() => {
		const roleParams = params.get('role')
		if(!config.channel && roleParams !== 'audience'){
			// history.push('/')
		}
		
	}, [config.channel, history, params])
	
	useEffect(() => {
		if(config.channel && localClient._created && localClient._joined === false){
			console.error('这里是config'+config.uid)
			localClient
				.join(config)
				.then((uid) => {
					if(config.host) {
						localClient.publish()
					}
					mutationCtx.updateConfig({uid})
					mutationCtx.stopLoading()
				})
				.catch((err) => {
					// mutationCtx.toastError(`Media ${err.info}`)
					// routerCtx.history.push('/')
				})
		}
	}, [localClient, mutationCtx, config, routerCtx])
	
	const handleClick = (name) => {
		return (evt) => {
			evt.stopPropagation()
			switch (name) {
				case 'video': {
					muteVideo ? localStream.muteVideo() : localStream.unmuteVideo()
					setMuteVideo(!muteVideo)
					break
				}
				case 'audio': {
					muteAudio ? localStream.muteAudio() : localStream.unmuteAudio()
					setMuteAudio(!muteAudio)
					break
				}
				case 'screen': {
					if(stateCtx.screen) {
						// localClient
						// 	.createRTCStream({
						// 		token: null,
						// 		channel: stateCtx.config.channelName,
						// 		microphoneId: stateCtx.config.microphoneId,
						// 		resolution: stateCtx.config.resolution,
						// 		muteVideo: muteVideo,
						// 		muteAudio: muteAudio,
						// 		// beauty: stateCtx.beauty
						// 	})
						// 	.then(() => {
						// 		localClient.publish()
						// 		mutationCtx.setScreen(false)
						// 	})
						// 	.catch((err) => {
						// 		mutationCtx.toastError('Media ${err.info}')
						// 		// routerCtx.history.push('/')
						// 	})
						localClient.stopLiveStreaming()
							.then(() => {
								localClient.closeStream()
								localClient.unpublish()
								mutationCtx.setScreen(false)
							})
							.catch((err) => {
								console.error(err)
								mutationCtx.toastError(`Media ${err.info}`)
							})
					} else {
						localClient
							.createScreenSharingStream({
								token: null,
								channel: stateCtx.config.channelName,
								microphoneId: stateCtx.config.microphoneId,
								cameraId: stateCtx.config.cameraId,
								resolution: stateCtx.config.resolution
							})
							.then(() => {
								localClient.publish()
								console.error("publish success!")
								mutationCtx.setScreen(true)
								console.log(localClient)
								console.error(stateCtx.config.resolution)
							})
							.catch((err) => {
								console.log(err)
								mutationCtx.toastError(`Media ${err.info}`)
							})
					}
					break
				}
				case 'profile': {
					break
				}
				default:
					throw new Error('Unknown click handler, name: ${name}')
			}
		}
	}
	
	const handleDoubleClick = (stream) => {
		mutationCtx.setCurrentStream(stream)
	}
	
	const otherStreams = useMemo(() => {
		return stateCtx.streams.filter(
			(it) => it.getId() !== currentStream.getId()
		)
	}, [stateCtx.streams, currentStream])

	const nick_name = useMemo(() => {
		return () => nickName
	}, [nickName])

	const image_photo = useMemo(() => {
		return () => photo
	}, [photo])
	return (
		<div className="room">
			<div className="layout-player-main">
			<div className="current-view">
				<div className="nav">
					<div className="avatar-container">
						<div className="default-avatar">
							{/*<Img imageUrl={image_photo()} defaultImg={'../assets/avatar.png'} />*/}
						</div>
						<div className="avatar-text">{nick_name()}</div>
						{/*<div className="like"></div>*/}
					</div>
					{/*<Tooltip title="start">*/}
					{/*	<div*/}
					{/*		className="quit"*/}
					{/*		onClick={() => {*/}
					{/*			// localClient.leave().then(() => {*/}
					{/*			// 	mutationCtx.clearAllStream()*/}
					{/*			// 	// mutationCtx.resetState()*/}
					{/*			// 	// routerCtx.history.push('/')*/}
					{/*			// })*/}
					{/*			*/}
					{/*		}}*/}
					{/*	></div>*/}
					{/*</Tooltip>*/}
				</div>
				{currentStream ? (
					<StreamPlayer
						className={'main-stream-profile'}
						showProfile={stateCtx.profile}
						local={
							config.host
								? currentStream &&
								localStream &&
								currentStream.getId() === localStream.getId()
								: false
						}
						stream={currentStream}
						onDoubleClick={handleDoubleClick}
						uid={currentStream.getId()}
						domId={`stream-player-${currentStream.getId()}`}
						>
					</StreamPlayer>
				) : null}
				<div className={classes.menuContainer}>
					{config.host && (
						<div className={classes.menu}>
							<Tooltip title={muteVideo ? 'mute-video' : 'unmute-video'}>
								<i
									onClick={handleClick('video')}
									className={clsx(
										classes.customBtn,
										muteVideo ? 'mute-video' : 'unmute-video'
									)}
								/>
							</Tooltip>
							<Tooltip title={muteAudio ? 'mute-audio' : 'unmute-audio'}>
								<i
									onClick={handleClick('audio')}
									className={clsx(
										classes.customBtn,
										muteAudio ? 'mute-audio' : 'unmute-audio'
									)}
								/>
							</Tooltip>
							<Tooltip title={stateCtx.screen ? 'stop-screen-share' : 'start-screen-share'}>
								<i
									onClick={handleClick('screen')}
									className={clsx(
										classes.customBtn,
										stateCtx.screen ? 'start-screen-share' : 'stop-screen-share'
									)}
								/>
							</Tooltip>
						</div>
					)}
				</div>
				<div className="stream-container">
					{stateCtx.otherStreams.map((stream, index) => (
						<StreamPlayer
							className={'stream-profile'}
							showProfile={stateCtx.profile}
							local={
								config.host
									? stream.getId() === localStream && localStream.getId()
									: false
							}
							key={`${index}${stream.getId()}`}
							stream={stream}
							isPlaying={stream.isPlaying()}
							uid={stream.getId()}
							domId={`stream-player-${stream.getId()}`}
							onDoubleClick={handleDoubleClick}
							showUid={true}
						></StreamPlayer>
					))}
				</div>
			</div>
		</div>
		<div className="layout-player-aside">
			<div className="layout-player-asideMain">
				<div className="camera"></div>
				<div className="chatTabContainer">
					<div className="chatTabContainer-title"></div>
					<div className="chatTabContainer-wrapper">
						<div className="chatTabContainer-content is-active"></div>
						<div className="chatTabContainer-content"></div>
					</div>
				</div>
				
			</div>
		</div>
		</div>
		
	)
	
}

export default React.memo(RoomPage)