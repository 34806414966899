const readDefaultState = () => {
	try {
		return JSON.parse(window.sessionStorage.getItem('custom_storage'))
	}catch (e) {
		return {}
	}
}

export const defaultState = {
	// loading effect
	loading: false,
	// media devices
	streams: [],
	localStream: null,
	currentStream: null,
	otherStreams: [],
	devicesList: [],
	// web sdk params
	config: {
		uid: 0,
		host: true,
		url: '',
		channelName: '',
		token: '',
		resolution: '1080p',
		...readDefaultState(),
		microphoneId: '',
		cameraId: '',
		iv: '',
		cataId: ''
	},
	agoraClient: null,
	mode: 'live',
	codec: 'h264',
	muteVideo: true,
	muteAudio: true,
	screen: false,
	profile: false
	// beauty: false
}