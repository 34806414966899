import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouterHook } from './utils/use-router'
import { Route, Switch } from 'react-router-dom'
import Room from './pages/room'
import NoFoundPage from "./pages/404";

function App() {
	return (
		<BrowserRouterHook>
			<Switch>
				{/*<Route exact path="/room/:name" component={Room}></Route>*/}
				<Route exact name="直播间" path="/room" component={Room}></Route>
				{/*<Route exact path="*" component={NoFoundPage}></Route>*/}
			</Switch>
		</BrowserRouterHook>
	);
}

export default App;
